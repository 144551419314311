/* Utilities */
.table.table-hover-pointer tbody tr:hover {
  cursor: pointer;
}

/* Bootstrap Utility extensions */
.card-body-height {
  height: 21.25rem;
  overflow: hidden;
  overflow-y: auto;
}

/* Update styles to deal with nav tabs as buttons (Bootstrap 4 vs 5) */
.nav-tabs .nav-item .nav-link {
  background: inherit;
}


.Hero {
  background-image: url("./lightGeo.png");
  background-position: center top;
  background-size: 100% auto;
}

/* Form Elements */

button.arrow-none:after {
  margin: 0;
  background: transparent;
  display: none;
  width: inherit;
  height: inherit;
}

/* Logo */
a.navbar-brand {
  margin-right: 0;
  margin-left: -10px;
}
  img.navbar-brand-logo-short {
    min-width: inherit !important;
    width: 20px !important;
  }


/* Transaction Page Header Navigation Tabs */


/* Role selector - large radio buttons */
.role-selector {}
    .role-selector .custom-checkbox-card {
        height: 10rem;
    }
      .role-selector .custom-checkbox-card i {
        font-size: 3rem;
      }


/* Radio inputs as cards (Source: https://jsfiddle.net/djibe89/at2cv9q1/) */

.card-input label {
  width: 100%;
}

.card-input-element+.card {
  height: 1rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid transparent;
  border-radius: 4px;
}

.card-input-element+.card:hover {
  cursor: pointer;
}

.card-input-element:checked+.card {
  border: 2px solid var(--primary);
  color: var(--primary);
  -webkit-transition: border .3s;
  -o-transition: border .3s;
  transition: border .3s;
}

.card-input-element:checked+.card::after {
  content: "\e8c8";
  color: var(--primary);
  font-family: 'unicons' !important;
  font-size: 24px;
  -webkit-animation-name: fadeInCheckbox;
  animation-name: fadeInCheckbox;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@-webkit-keyframes fadeInCheckbox {
  from {
    opacity: 0;
    -webkit-transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
  }
}

@keyframes fadeInCheckbox {
  from {
    opacity: 0;
    transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}


/* Active Tx */
.steps-active-transaction {}

  .steps-active-transaction .step-icon {
    box-shadow: 0px 0px 1px 1px #0000001a;
  }
  /* Pulse the active step */
  .steps-active-transaction .active .step-icon {
    animation: pulse-animation 2s infinite;
  }
    @keyframes pulse-animation {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
      }
    }
  /* Headers have normal font weight unless active */
  .steps-active-transaction h5 {
    font-weight: normal;
  }
  .steps-active-transaction .active h5 {
    font-weight: bold;
  }
